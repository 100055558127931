import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const LabelWrapper = styled('span')(
    ({ theme }) => `
      padding: ${theme.spacing(0.5, 1)};
      font-size: ${theme.typography.pxToRem(13)};
      display: inline-flex;
      align-items: center;
      justify-content: center;
      max-height: ${theme.spacing(3)};
      
      &.MuiLabel {
        &-primary {
          color: ${theme.palette.primary.main}
        }
        
        &-secondary {
          color: ${theme.palette.secondary.main}
        }
        
        &-success {
          color: ${theme.palette.success.main}
        }
        
        &-warning {
          color: ${theme.palette.warning.main}
        }
              
        &-error {
          color: ${theme.palette.error.main}
        }
        
        &-info {
          color: ${theme.palette.info.main}
        }
      }
`
);

const Label = ({
    className = '',
    color = 'secondary',
    children,
    ...rest
}: {
    className?: string;
    color: string;
    children: any;
}) => {
    return (
        <LabelWrapper className={'MuiLabel-' + color} {...rest}>
            {children}
        </LabelWrapper>
    );
};

Label.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'secondary', 'error', 'warning', 'success', 'info'])
};

export default Label;
