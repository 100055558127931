import { useState, createContext, ReactNode, FC } from 'react';

interface SidebarContext {
    sidebarToggle: boolean;
    toggleSidebar?: () => void;
}

const defaultState = {
    sidebarToggle: false
};

export const SidebarContext = createContext<SidebarContext>(defaultState);

type Props = { children: ReactNode };

export const SidebarProvider: FC<Props> = ({ children }) => {
    const [sidebarToggle, setSidebarToggle] = useState(false);
    const toggleSidebar = () => {
        setSidebarToggle(!sidebarToggle);
    };

    return <SidebarContext.Provider value={{ sidebarToggle, toggleSidebar }}>{children}</SidebarContext.Provider>;
};
