/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BirthCountPerMonth } from '../models/BirthCountPerMonth';
import type { BirthPerYear } from '../models/BirthPerYear';
import type { BirthRateByYearResponse } from '../models/BirthRateByYearResponse';
import type { BirthSortBy } from '../models/BirthSortBy';
import type { SortingDirection } from '../models/SortingDirection';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class BirthService {

    /**
     * @param year
     * @param sortBy
     * @param sortDir
     * @returns BirthRateByYearResponse Success
     * @throws ApiError
     */
    public static getYearlyBirthsAsync(
        year?: number,
        sortBy?: BirthSortBy,
        sortDir?: SortingDirection,
    ): CancelablePromise<BirthRateByYearResponse> {
        return __request({
            method: 'GET',
            path: `/v1/birth/yearly`,
            query: {
                'year': year,
                'sortBy': sortBy,
                'sortDir': sortDir,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @param year
     * @returns BirthPerYear Success
     * @throws ApiError
     */
    public static getBirthsInYear(
        year: number,
    ): CancelablePromise<BirthPerYear> {
        return __request({
            method: 'GET',
            path: `/v1/birth/${year}`,
        });
    }

    /**
     * @param year
     * @param sortBy
     * @param sortDir
     * @returns BirthCountPerMonth Success
     * @throws ApiError
     */
    public static getMonthlyBirthsAsync(
        year?: number,
        sortBy?: BirthSortBy,
        sortDir?: SortingDirection,
    ): CancelablePromise<Array<BirthCountPerMonth>> {
        return __request({
            method: 'GET',
            path: `/v1/birth/monthly`,
            query: {
                'year': year,
                'sortBy': sortBy,
                'sortDir': sortDir,
            },
        });
    }

}