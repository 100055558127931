import SuspenseLoader from 'src/components/SuspenseLoader';
import Index from './views/index';
import React from 'react';
import { Suspense } from 'react';
import DashboardLayout from 'src/layouts/DashboardLayout';
import { Navigate } from 'react-router-dom';

import { RootState } from './app/rootReducer';
import { useSelector } from 'react-redux';

import { LinearProgress } from '@mui/material';
import LoginView from 'src/views/auth/Login';
import Account from './views/account/index';

import Users from 'src/views/user';

const ProtectedRoute = (props: { children: any }) => {
    const { children } = props;
    const { isAuthenticated, isAuthenticationLoading } = useSelector((state: RootState) => state.user);
    if (isAuthenticationLoading) return <LinearProgress />;
    return isAuthenticated ? children : <Navigate to={'/login-page'} />;
};

// eslint-disable-next-line react/display-name
const Loader = (Component: any) => (props: any) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <Component {...props} />
        </Suspense>
    );

const routes = [
    {
        path: '*',

        children: [
            {
                path: 'login-page',
                element: <LoginView />
            },
            {
                path: '*',
                element: <div>Page not found</div>
            }
        ]
    },
    {
        path: '*',
        element: (
            <ProtectedRoute>
                <DashboardLayout />
            </ProtectedRoute>
        ),

        children: [
            {
                path: '',
                element: <Index />
            },
            {
                path: 'profile',
                element: <Account />
            },
            {
                path: 'users',
                element: <Users />
            }
        ]
    }
];

export default routes;
