import { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Tooltip,
    Box,
    Card,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableContainer,
    Typography,
    useTheme
} from '@mui/material';

import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import moment from 'moment';

import { User, Locale, ActiveStatus, Role } from 'src/services/api';
import { getPreferredLanguageLabel } from 'src/components/labels/getPreferredLanguageLabel';
import { GetStatusLabel } from 'src/components/labels/GetStatusLabel';
import { getRoleLabel } from 'src/components/labels/getRoleLabel';
import Label from 'src/components/Label';
import { useTranslation } from 'react-i18next';

const columns = [
    { name: 'username', label: 'username' },
    { name: 'email', label: 'email' },
    { name: 'roles', label: 'roles' },
    { name: 'preferredLanguage', label: 'preferredLanguage' },
    { name: 'status', label: 'status' },
    { name: 'createdAt', label: 'createdAt' },
    { name: 'actions', label: 'actions' }
];

const UsersTable = ({
    fetchUsers,
    users,
    count,
    page,
    setPage,
    pageSize,
    setPageSize,
    handleEditUser
}: {
    fetchUsers: (params: any) => void;
    users: User[];
    count: number;
    page: number;
    setPage: any;
    pageSize: number;
    setPageSize: any;
    handleEditUser: any;
}) => {
    const [t] = useTranslation();

    useEffect(() => {
        fetchUsers({ params: { page: page, pageSize } });
    }, [page, pageSize]);

    const handlePageChange = (event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event: any) => {
        setPageSize(parseInt(event.target.value));
    };

    const theme = useTheme();

    return (
        <Card variant="outlined">
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column, i) => (
                                <TableCell key={i}>{column.label}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.length > 0 &&
                            users.map((user: User) => {
                                return (
                                    <TableRow hover key={user.id}>
                                        <TableCell>
                                            <Typography variant="body1" gutterBottom noWrap>
                                                {user.username}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1" gutterBottom noWrap>
                                                {user.email}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1" gutterBottom noWrap>
                                                {user.roles?.map((role: Role, i) => (
                                                    <Label key={i} color="primary">
                                                        {getRoleLabel(role)}
                                                    </Label>
                                                ))}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1" gutterBottom noWrap>
                                                {getPreferredLanguageLabel({
                                                    userPreferredLanguage: user?.locale as Locale,
                                                    t: t
                                                })}
                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Typography variant="body1" gutterBottom noWrap>
                                                {
                                                    <GetStatusLabel
                                                        userStatus={user.activeStatus as ActiveStatus}
                                                        t={t}
                                                    />
                                                }
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1" gutterBottom noWrap>
                                                {moment(user.createdAt).format('DD-MM-YYYY')}
                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Tooltip title="Edit User" arrow>
                                                <IconButton
                                                    sx={{
                                                        color: theme.palette.primary.main
                                                    }}
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() => handleEditUser(user)}
                                                >
                                                    <EditTwoToneIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box p={2}>
                <TablePagination
                    component="div"
                    count={count}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    page={page}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[5, 10, 25, 30]}
                    labelRowsPerPage={t('view.common.pagination.rows_per_page')}
                />
            </Box>
        </Card>
    );
};

UsersTable.propTypes = {
    cryptoOrders: PropTypes.array.isRequired
};

UsersTable.defaultProps = {
    cryptoOrders: []
};

export default UsersTable;
