/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IdentityUser } from '../models/IdentityUser';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class IdentiyUsersService {

    /**
     * @param email
     * @param page
     * @param pageSize
     * @param includeAlreadyEnrolled
     * @returns IdentityUser Success
     * @throws ApiError
     */
    public static searchUsersAsync(
        email: string,
        page?: number,
        pageSize?: number,
        includeAlreadyEnrolled?: boolean,
    ): CancelablePromise<Array<IdentityUser>> {
        return __request({
            method: 'GET',
            path: `/v1/identity-user/search-users`,
            query: {
                'Email': email,
                'Page': page,
                'PageSize': pageSize,
                'IncludeAlreadyEnrolled': includeAlreadyEnrolled,
            },
        });
    }

}