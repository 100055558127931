/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgeAvgBySex } from '../models/AgeAvgBySex';
import type { AgeGroupResponse } from '../models/AgeGroupResponse';
import type { AgeGroupWithSexResponse } from '../models/AgeGroupWithSexResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AgeService {

    /**
     * @param maxAge
     * @param minAge
     * @param range
     * @param useAnalytics
     * @returns AgeGroupResponse Success
     * @throws ApiError
     */
    public static getPopulationAgeAsync(
        maxAge: number,
        minAge: number,
        range: number,
        useAnalytics?: boolean,
    ): CancelablePromise<Array<AgeGroupResponse>> {
        return __request({
            method: 'GET',
            path: `/v1/age/ranges`,
            query: {
                'MaxAge': maxAge,
                'MinAge': minAge,
                'Range': range,
                'UseAnalytics': useAnalytics,
            },
        });
    }

    /**
     * @param maxAge
     * @param minAge
     * @param range
     * @param useAnalytics
     * @returns AgeGroupWithSexResponse Success
     * @throws ApiError
     */
    public static getPopulationAgeBySexAsync(
        maxAge: number,
        minAge: number,
        range: number,
        useAnalytics?: boolean,
    ): CancelablePromise<Array<AgeGroupWithSexResponse>> {
        return __request({
            method: 'GET',
            path: `/v1/age/ranges-by-sex`,
            query: {
                'MaxAge': maxAge,
                'MinAge': minAge,
                'Range': range,
                'UseAnalytics': useAnalytics,
            },
        });
    }

    /**
     * @param useAnalytics
     * @returns AgeAvgBySex Success
     * @throws ApiError
     */
    public static getAverageAgeBySexAsync(
        useAnalytics: boolean = false,
    ): CancelablePromise<Array<AgeAvgBySex>> {
        return __request({
            method: 'GET',
            path: `/v1/age/avg`,
            query: {
                'useAnalytics': useAnalytics,
            },
        });
    }

}