import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function Logo() {
    const [t] = useTranslation();
    return (
        <Typography variant="h4" color="primary">
            {t('view.pisa')}
        </Typography>
    );
}

export default Logo;
