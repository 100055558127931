/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EnrollUserRequest } from '../models/EnrollUserRequest';
import type { UpdateSelfRequest } from '../models/UpdateSelfRequest';
import type { UpdateUserRequest } from '../models/UpdateUserRequest';
import type { User } from '../models/User';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * @returns User Success
     * @throws ApiError
     */
    public static getSelfAsync(): CancelablePromise<User> {
        return __request({
            method: 'GET',
            path: `/v1/user/self`,
        });
    }

    /**
     * @param requestBody
     * @returns User Success
     * @throws ApiError
     */
    public static updateSelf(
        requestBody?: UpdateSelfRequest,
    ): CancelablePromise<User> {
        return __request({
            method: 'PATCH',
            path: `/v1/user/self`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns User Success
     * @throws ApiError
     */
    public static getUserByIdAsync(
        id: string,
    ): CancelablePromise<User> {
        return __request({
            method: 'GET',
            path: `/v1/user/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static updateUserAsync(
        id: string,
        requestBody?: UpdateUserRequest,
    ): CancelablePromise<any> {
        return __request({
            method: 'PATCH',
            path: `/v1/user/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns User Success
     * @throws ApiError
     */
    public static enrollUserAsync(
        id: string,
        requestBody?: EnrollUserRequest,
    ): CancelablePromise<User> {
        return __request({
            method: 'POST',
            path: `/v1/user/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param page
     * @param pageSize
     * @returns User Success
     * @throws ApiError
     */
    public static getUsersListAsync(
        page?: number,
        pageSize?: number,
    ): CancelablePromise<Array<User>> {
        return __request({
            method: 'GET',
            path: `/v1/user/users-list`,
            query: {
                'Page': page,
                'PageSize': pageSize,
            },
        });
    }

}