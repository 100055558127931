import { alpha, lighten, darken, Direction } from '@mui/material';
import '@mui/lab/themeAugmentation';
import { createTheme as createMuiTheme } from '@mui/material';
import { Theme, ThemeOptions } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';

declare module '@mui/material/styles' {
    interface Theme {
        header: {
            height: string;
            textColor: string;
            background: string;
            boxShadow: string;
        };
        sidebar: {
            width: number;
        };
    }
}

const themeColors = {
    primary: '#8b5cf6',
    secondary: '#6E759F',
    success: '#44D600',
    warning: '#FFA319',
    error: '#FF1943',
    info: '#33C2FF',
    black: '#223354',
    white: '#ffffff',
    primaryAlt: '#000C57'
};

export const colors = {
    gradients: {
        blue1: 'linear-gradient(135deg, #6B73FF 0%, #000DFF 100%)',
        blue2: 'linear-gradient(135deg, #ABDCFF 0%, #0396FF 100%)',
        blue3: 'linear-gradient(127.55deg, #141E30 3.73%, #243B55 92.26%)',
        orange1: 'linear-gradient(135deg, #FCCF31 0%, #F55555 100%)',
        orange2: 'linear-gradient(135deg, #FFD3A5 0%, #FD6585 100%)',
        purple1: 'linear-gradient(135deg, #43CBFF 0%, #9708CC 100%)',
        pink1: 'linear-gradient(135deg, #F6CEEC 0%, #D939CD 100%)',
        pink2: 'linear-gradient(135deg, #F761A1 0%, #8C1BAB 100%)',
        green1: 'linear-gradient(135deg, #FFF720 0%, #3CD500 100%)',
        black1: 'linear-gradient(100.66deg, #434343 6.56%, #000000 93.57%)'
    },
    shadows: {
        success: '0px 1px 4px rgba(68, 214, 0, 0.25), 0px 3px 12px 2px rgba(68, 214, 0, 0.35)',
        error: '0px 1px 4px rgba(255, 25, 67, 0.25), 0px 3px 12px 2px rgba(255, 25, 67, 0.35)',
        info: '0px 1px 4px rgba(51, 194, 255, 0.25), 0px 3px 12px 2px rgba(51, 194, 255, 0.35)',
        primary: '0px 1px 4px rgba(85, 105, 255, 0.25), 0px 3px 12px 2px rgba(85, 105, 255, 0.35)',
        warning: '0px 1px 4px rgba(255, 163, 25, 0.25), 0px 3px 12px 2px rgba(255, 163, 25, 0.35)',
        card: '0px 9px 16px rgba(159, 162, 191, 0.18), 0px 2px 2px rgba(159, 162, 191, 0.32)',
        cardSm: '0px 2px 3px rgba(159, 162, 191, 0.18), 0px 1px 1px rgba(159, 162, 191, 0.32)',
        cardLg: '0 5rem 14rem 0 rgb(255 255 255 / 30%), 0 0.8rem 2.3rem rgb(0 0 0 / 60%), 0 0.2rem 0.3rem rgb(0 0 0 / 45%)'
    },
    layout: {
        general: {
            bodyBg: '#f2f5f9'
        },
        sidebar: {
            background: themeColors.white,
            textColor: themeColors.secondary,
            dividerBg: '#f2f5f9',
            menuItemColor: '#242E6F',
            menuItemColorActive: themeColors.primary,
            menuItemBg: 'transparent',
            menuItemBgActive: '#f2f5f9',
            menuItemIconColor: lighten(themeColors.secondary, 0.3),
            menuItemIconColorActive: themeColors.primary,
            menuItemHeadingColor: darken(themeColors.secondary, 0.3)
        }
    },
    alpha: {
        white: {
            5: alpha(themeColors.white, 0.02),
            10: alpha(themeColors.white, 0.1),
            30: alpha(themeColors.white, 0.3),
            50: alpha(themeColors.white, 0.5),
            70: alpha(themeColors.white, 0.7),
            100: themeColors.white
        },
        trueWhite: {
            5: alpha(themeColors.white, 0.02),
            10: alpha(themeColors.white, 0.1),
            30: alpha(themeColors.white, 0.3),
            50: alpha(themeColors.white, 0.5),
            70: alpha(themeColors.white, 0.7),
            100: themeColors.white
        },
        black: {
            5: alpha(themeColors.black, 0.02),
            10: alpha(themeColors.black, 0.1),
            30: alpha(themeColors.black, 0.3),
            50: alpha(themeColors.black, 0.5),
            70: alpha(themeColors.black, 0.7),
            100: themeColors.black
        }
    },
    secondary: {
        lighter: lighten(themeColors.secondary, 0.85),
        light: lighten(themeColors.secondary, 0.25),
        main: themeColors.secondary,
        dark: darken(themeColors.secondary, 0.2)
    },
    primary: {
        lighter: lighten(themeColors.primary, 0.85),
        light: lighten(themeColors.primary, 0.3),
        main: themeColors.primary,
        dark: darken(themeColors.primary, 0.2)
    },
    success: {
        lighter: lighten(themeColors.success, 0.85),
        light: lighten(themeColors.success, 0.3),
        main: themeColors.success,
        dark: darken(themeColors.success, 0.2)
    },
    warning: {
        lighter: lighten(themeColors.warning, 0.85),
        light: lighten(themeColors.warning, 0.3),
        main: themeColors.warning,
        dark: darken(themeColors.warning, 0.2)
    },
    error: {
        lighter: lighten(themeColors.error, 0.85),
        light: lighten(themeColors.error, 0.3),
        main: themeColors.error,
        dark: darken(themeColors.error, 0.2)
    },
    info: {
        lighter: lighten(themeColors.info, 0.85),
        light: lighten(themeColors.info, 0.3),
        main: themeColors.info,
        dark: darken(themeColors.info, 0.2)
    }
};

export const ltr_typography: TypographyOptions = {
    fontFamily: [
        'Inter',
        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
    ].join(','),
    fontSize: 14,
    htmlFontSize: 15,
    h1: {
        fontWeight: 700,
        fontSize: 30
    },
    h2: {
        fontWeight: 700,
        fontSize: 25
    },
    h3: {
        fontWeight: 700,
        fontSize: 21,
        lineHeight: 1.4,
        color: colors.alpha.black[100]
    },
    h4: {
        fontWeight: 700,
        fontSize: 16
    },
    h5: {
        fontWeight: 700,
        fontSize: 14
    },
    h6: {
        fontSize: 15
    },
    body1: {
        fontSize: 14
    },
    body2: {
        fontSize: 14
    },
    button: {
        fontSize: 14,
        fontWeight: 700
    },
    caption: {
        fontSize: 13,
        textTransform: 'uppercase',
        color: colors.alpha.black[50]
    },
    subtitle1: {
        fontSize: 14,
        color: colors.alpha.black[70]
    },
    subtitle2: {
        fontWeight: 400,
        fontSize: 15,
        color: colors.alpha.black[70]
    },
    overline: {
        fontSize: 13,
        fontWeight: 700,
        textTransform: 'uppercase'
    }
};

export const rtl_typography: TypographyOptions = {
    fontFamily: ['Rabar'].join(','),
    fontSize: 15,
    htmlFontSize: 15,
    h1: {
        fontWeight: 600,
        fontSize: 35,
        letterSpacing: '-0.24px'
    },
    h2: {
        fontWeight: 700,
        fontSize: 27,
        letterSpacing: '-0.26px'
    },
    h3: {
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: '-0.06px'
    },
    h4: {
        fontWeight: 500,
        fontSize: 20,
        letterSpacing: '-0.06px'
    },
    h5: {
        fontWeight: 500,
        fontSize: 18,
        letterSpacing: '-0.05px'
    },
    h6: {
        fontWeight: 500,
        fontSize: 16,
        letterSpacing: '-0.05px'
    },
    overline: {
        fontWeight: 500,
        textTransform: 'capitalize',
        fontSize: '0.97rem',
        fontFamily: 'Rabar-light'
    },
    button: {
        textTransform: 'capitalize',
        fontWeight: 600,
        lineHeight: '1.25rem',
        fontSize: 14
    },
    body1: {
        fontFamily: 'Rabar-light',
        fontSize: 14
    },
    body2: {
        fontFamily: 'Rabar-light',
        fontSize: 14
    },
    caption: {
        fontFamily: 'Rabar-light',
        fontSize: 13,
        textTransform: 'uppercase',
        color: colors.alpha.black[50]
    },
    subtitle1: {
        fontFamily: 'Rabar-light',
        fontSize: 14,
        color: colors.alpha.black[70]
    },
    subtitle2: {
        fontWeight: 400,
        fontSize: 15,
        color: colors.alpha.black[70]
    }
};

const base: any = {
    // direction: i18n.dir(),
    colors: {
        gradients: {
            blue1: colors.gradients.blue1,
            blue2: colors.gradients.blue2,
            blue3: colors.gradients.blue3,
            orange1: colors.gradients.orange1,
            orange2: colors.gradients.orange2,
            purple1: colors.gradients.purple1,
            pink1: colors.gradients.pink1,
            pink2: colors.gradients.pink2,
            green1: colors.gradients.green1,
            black1: colors.gradients.black1
        },
        shadows: {
            success: colors.shadows.success,
            error: colors.shadows.error,
            primary: colors.shadows.primary,
            warning: colors.shadows.warning
        },
        alpha: {
            white: {
                5: alpha(themeColors.white, 0.02),
                10: alpha(themeColors.white, 0.1),
                30: alpha(themeColors.white, 0.3),
                50: alpha(themeColors.white, 0.5),
                70: alpha(themeColors.white, 0.7),
                100: themeColors.white
            },
            trueWhite: {
                5: alpha(themeColors.white, 0.02),
                10: alpha(themeColors.white, 0.1),
                30: alpha(themeColors.white, 0.3),
                50: alpha(themeColors.white, 0.5),
                70: alpha(themeColors.white, 0.7),
                100: themeColors.white
            },
            black: {
                5: alpha(themeColors.black, 0.02),
                10: alpha(themeColors.black, 0.1),
                30: alpha(themeColors.black, 0.3),
                50: alpha(themeColors.black, 0.5),
                70: alpha(themeColors.black, 0.7),
                100: themeColors.black
            }
        },
        secondary: {
            lighter: alpha(themeColors.secondary, 0.1),
            light: lighten(themeColors.secondary, 0.3),
            main: themeColors.secondary,
            dark: darken(themeColors.secondary, 0.2)
        },
        primary: {
            lighter: alpha(themeColors.primary, 0.1),
            light: lighten(themeColors.primary, 0.3),
            main: themeColors.primary,
            dark: darken(themeColors.primary, 0.2)
        },
        success: {
            lighter: alpha(themeColors.success, 0.1),
            light: lighten(themeColors.success, 0.3),
            main: themeColors.success,
            dark: darken(themeColors.success, 0.2)
        },
        warning: {
            lighter: alpha(themeColors.warning, 0.1),
            light: lighten(themeColors.warning, 0.3),
            main: themeColors.warning,
            dark: darken(themeColors.warning, 0.2)
        },
        error: {
            lighter: alpha(themeColors.error, 0.1),
            light: lighten(themeColors.error, 0.3),
            main: themeColors.error,
            dark: darken(themeColors.error, 0.2)
        },
        info: {
            lighter: alpha(themeColors.info, 0.1),
            light: lighten(themeColors.info, 0.3),
            main: themeColors.info,
            dark: darken(themeColors.info, 0.2)
        }
    },

    general: {
        reactFrameworkColor: '#00D8FF',
        borderRadiusSm: '4px',
        borderRadius: '6px',
        borderRadiusLg: '10px',
        borderRadiusXl: '18px'
    },
    sidebar: {
        background: colors.layout.sidebar.background,
        textColor: colors.layout.sidebar.textColor,
        dividerBg: colors.layout.sidebar.dividerBg,
        menuItemColor: colors.layout.sidebar.menuItemColor,
        menuItemColorActive: colors.layout.sidebar.menuItemColorActive,
        menuItemBg: colors.layout.sidebar.menuItemBg,
        menuItemBgActive: colors.layout.sidebar.menuItemBgActive,
        menuItemIconColor: colors.layout.sidebar.menuItemIconColor,
        menuItemIconColorActive: colors.layout.sidebar.menuItemIconColorActive,
        menuItemHeadingColor: colors.layout.sidebar.menuItemHeadingColor,
        boxShadow: '2px 0 3px rgba(159, 162, 191, 0.18), 1px 0 1px rgba(159, 162, 191, 0.32)',
        width: '280px'
    },
    header: {
        height: '88px',
        background: colors.alpha.white[100],
        boxShadow: colors.shadows.cardSm,
        textColor: colors.secondary.main
    },
    spacing: 8,
    palette: {
        common: {
            black: colors.alpha.black[100],
            white: colors.alpha.white[100]
        },
        mode: 'light',
        primary: {
            light: colors.primary.light,
            main: colors.primary.main,
            dark: colors.primary.dark,
            contrastText: colors.alpha.white[100]
        },
        secondary: {
            light: colors.secondary.light,
            main: colors.secondary.main,
            dark: colors.secondary.dark
        },
        error: {
            light: colors.error.light,
            main: colors.error.main,
            dark: colors.error.dark,
            contrastText: colors.alpha.white[100]
        },
        success: {
            light: colors.success.light,
            main: colors.success.main,
            dark: colors.success.dark,
            contrastText: colors.alpha.white[100]
        },
        info: {
            light: colors.info.light,
            main: colors.info.main,
            dark: colors.info.dark,
            contrastText: colors.alpha.white[100]
        },
        warning: {
            light: colors.warning.light,
            main: colors.warning.main,
            dark: colors.warning.dark,
            contrastText: colors.alpha.white[100]
        },
        grey: {
            50: '#FBFBFB',
            100: '#F3F5F6',
            200: '#E8EAED',
            300: '#DCE0E5',
            400: '#bdbdbd',
            500: '#9e9e9e',
            600: '#757575',
            700: '#616161',
            800: '#424242',
            900: '#212121',
            A100: '#d5d5d5',
            A200: '#aaaaaa',
            A400: '#303030',
            A700: '#616161'
        },
        text: {
            primary: colors.alpha.black[100],
            secondary: colors.alpha.black[70],
            disabled: colors.alpha.black[50]
        },
        background: {
            paper: colors.alpha.white[100],
            default: colors.layout.general.bodyBg
        },
        action: {
            active: colors.alpha.black[100],
            hover: colors.primary.lighter,
            hoverOpacity: 0.1,
            selected: colors.alpha.black[10],
            selectedOpacity: 0.1,
            disabled: colors.alpha.black[50],
            disabledBackground: colors.alpha.black[5],
            disabledOpacity: 0.38,
            focus: colors.alpha.black[10],
            focusOpacity: 0.05,
            activatedOpacity: 0.12
        },
        tonalOffset: 0.5
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1840
        }
    },
    components: {
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: alpha(darken(themeColors.primaryAlt, 0.4), 0.2),
                    backdropFilter: 'blur(2px)',

                    '&.MuiBackdrop-invisible': {
                        backgroundColor: 'transparent',
                        backdropFilter: 'blur(2px)'
                    }
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    marginLeft: 8,
                    marginRight: 8,
                    fontWeight: 'bold'
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                'html, body, #root': {
                    width: '100%',
                    height: '100%'
                },
                '#nprogress .bar': {
                    background: colors.primary.main
                },
                '#nprogress .spinner-icon': {
                    borderTopColor: colors.primary.main,
                    borderLeftColor: colors.primary.main
                },
                '#nprogress .peg': {
                    boxShadow: '0 0 10px ' + colors.primary.main + ', 0 0 5px' + colors.primary.main
                },
                ':root': {
                    '--swiper-theme-color': colors.primary.main
                },
                code: {
                    background: colors.info.lighter,
                    color: colors.info.dark,
                    borderRadius: 4,
                    padding: 4
                },
                '@keyframes ripple': {
                    '0%': {
                        transform: 'scale(.8)',
                        opacity: 1
                    },
                    '100%': {
                        transform: 'scale(2.8)',
                        opacity: 0
                    }
                },
                '@keyframes float': {
                    '0%': {
                        transform: 'translate(0%, 0%)'
                    },
                    '100%': {
                        transform: 'translate(3%, 3%)'
                    }
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                iconOutlined: {
                    color: colors.alpha.black[50]
                },
                icon: {
                    top: 'calc(50% - 14px)'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '& .MuiInputAdornment-positionEnd.MuiInputAdornment-outlined': {
                        paddingRight: 6
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: colors.alpha.black[50]
                    },
                    '&.Mui-focused:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: colors.primary.main
                    }
                }
            }
        },
        MuiListSubheader: {
            styleOverrides: {
                colorPrimary: {
                    fontWeight: 'bold',
                    lineHeight: '40px',
                    fontSize: 13,
                    background: colors.alpha.black[5],
                    color: colors.alpha.black[70]
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                action: {
                    marginTop: -5,
                    marginBottom: -5
                },
                title: {
                    fontSize: 15
                }
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    borderRadius: '50px'
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                colorSecondary: {
                    background: colors.alpha.black[5],
                    color: colors.alpha.black[100],

                    '&:hover': {
                        background: colors.alpha.black[10]
                    }
                },
                deleteIcon: {
                    color: colors.error.light,

                    '&:hover': {
                        color: colors.error.main
                    }
                }
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',

                    '&.Mui-expanded': {
                        margin: 0
                    },
                    '&::before': {
                        display: 'none'
                    }
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    fontWeight: 'bold'
                },
                colorDefault: {
                    background: colors.alpha.black[30],
                    color: colors.alpha.white[100]
                }
            }
        },
        MuiAvatarGroup: {
            styleOverrides: {
                root: {
                    alignItems: 'center'
                },
                avatar: {
                    background: colors.alpha.black[10],
                    fontSize: 13,
                    color: colors.alpha.black[70],
                    fontWeight: 'bold',

                    '&:first-of-type': {
                        border: 0,
                        background: 'transparent'
                    }
                }
            }
        },
        MuiListItemAvatar: {
            styleOverrides: {
                alignItemsFlexStart: {
                    marginTop: 0
                }
            }
        },
        MuiPaginationItem: {
            styleOverrides: {
                page: {
                    fontSize: 13,
                    fontWeight: 'bold',
                    transition: 'all .2s'
                },
                textPrimary: {
                    '&.Mui-selected': {
                        boxShadow: colors.shadows.primary
                    },
                    '&.MuiButtonBase-root:hover': {
                        background: colors.alpha.black[5]
                    },
                    '&.Mui-selected.MuiButtonBase-root:hover': {
                        background: colors.primary.main
                    }
                }
            }
        },
        MuiButton: {
            defaultProps: {
                disableRipple: true
            },
            styleOverrides: {
                root: {
                    fontWeight: 'bold',
                    textTransform: 'none',
                    paddingLeft: 16,
                    paddingRight: 16,

                    '.MuiSvgIcon-root': {
                        transition: 'all .2s'
                    }
                },
                endIcon: {
                    marginRight: -8
                },
                containedSecondary: {
                    backgroundColor: colors.secondary.main,
                    color: colors.alpha.white[100],
                    border: '1px solid ' + colors.alpha.black[30]
                },
                outlinedSecondary: {
                    backgroundColor: colors.alpha.white[100],

                    '&:hover, &.MuiSelected': {
                        backgroundColor: colors.alpha.black[5],
                        color: colors.alpha.black[100]
                    }
                }
            }
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: false
            },
            styleOverrides: {
                root: {
                    borderRadius: 6
                }
            }
        },
        MuiToggleButton: {
            defaultProps: {
                disableRipple: true
            },
            styleOverrides: {
                root: {
                    color: colors.primary.main,
                    background: colors.alpha.white[100],
                    transition: 'all .2s',

                    '&:hover, &.Mui-selected, &.Mui-selected:hover': {
                        color: colors.alpha.white[100],
                        background: colors.primary.main
                    }
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    borderRadius: 6,

                    '& .MuiTouchRipple-root': {
                        borderRadius: 6
                    }
                },
                sizeSmall: {
                    padding: 4
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    margin: 0
                }
            }
        },

        MuiDivider: {
            styleOverrides: {
                root: {
                    background: colors.alpha.black[10],
                    border: 0,
                    height: 1
                },
                vertical: {
                    height: 'auto',
                    width: 1,

                    '&.MuiDivider-flexItem.MuiDivider-fullWidth': {
                        height: 'auto'
                    },
                    '&.MuiDivider-absolute.MuiDivider-fullWidth': {
                        height: '100%'
                    }
                },
                withChildren: {
                    '&:before, &:after': {
                        border: 0
                    }
                },
                wrapper: {
                    background: colors.alpha.white[100],
                    fontWeight: 'bold',
                    height: 24,
                    lineHeight: '24px',
                    marginTop: -12,
                    color: 'inherit',
                    textTransform: 'uppercase'
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    padding: 0
                },
                elevation0: {
                    boxShadow: 'none'
                },
                elevation: {
                    boxShadow: colors.shadows.card
                },
                elevation2: {
                    boxShadow: colors.shadows.cardSm
                },
                elevation24: {
                    boxShadow: colors.shadows.cardLg
                }
            }
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    borderRadius: 6,
                    height: 6
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    '& .MuiSlider-valueLabelCircle, .MuiSlider-valueLabelLabel': {
                        transform: 'none'
                    },
                    '& .MuiSlider-valueLabel': {
                        borderRadius: 6,
                        background: colors.alpha.black[100],
                        color: colors.alpha.white[100]
                    }
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    padding: 0,

                    '& .MuiListItem-button': {
                        transition: 'all .2s',

                        '& > .MuiSvgIcon-root': {
                            minWidth: 34
                        },

                        '& .MuiTouchRipple-root': {
                            opacity: 0.2
                        }
                    },
                    '& .MuiListItem-root.MuiButtonBase-root.Mui-selected': {
                        backgroundColor: colors.alpha.black[10]
                    }
                },
                padding: {
                    padding: '12px',

                    '& .MuiListItem-button': {
                        borderRadius: 6,
                        margin: '1px 0'
                    }
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    height: 38,
                    minHeight: 38,
                    overflow: 'visible'
                },
                indicator: {
                    height: 38,
                    minHeight: 38,
                    borderRadius: 6,
                    border: '1px solid ' + colors.primary.dark
                },
                scrollableX: {
                    overflow: 'visible !important'
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    padding: 0,
                    height: 38,
                    minHeight: 38,
                    borderRadius: 6,
                    transition: 'color .2s',
                    textTransform: 'capitalize',

                    '&.MuiButtonBase-root': {
                        minWidth: 'auto',
                        paddingLeft: 20,
                        paddingRight: 20,
                        marginRight: 4
                    },
                    '&.Mui-selected, &.Mui-selected:hover': {
                        color: colors.alpha.white[100],
                        zIndex: 5
                    },
                    '&:hover': {
                        color: colors.alpha.black[100]
                    }
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    padding: 12
                },
                list: {
                    padding: 12,

                    '& .MuiMenuItem-root.MuiButtonBase-root': {
                        fontSize: 14,
                        marginTop: 1,
                        marginBottom: 1,
                        transition: 'all .2s',
                        color: colors.alpha.black[70],

                        '& .MuiTouchRipple-root': {
                            opacity: 0.2
                        },

                        '&:hover, &:active, &.active, &.Mui-selected': {
                            color: colors.alpha.black[100],
                            background: lighten(colors.primary.lighter, 0.5)
                        }
                    }
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    '&.MuiButtonBase-root': {
                        color: colors.secondary.main,

                        '&:hover, &:active, &.active, &.Mui-selected': {
                            color: colors.alpha.black[100],
                            background: lighten(colors.primary.lighter, 0.5)
                        }
                    }
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                tag: {
                    margin: 1
                },
                root: {
                    '.MuiAutocomplete-inputRoot.MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
                        right: 14
                    }
                },
                clearIndicator: {
                    background: colors.error.lighter,
                    color: colors.error.main,
                    marginRight: 8,

                    '&:hover': {
                        background: colors.error.lighter,
                        color: colors.error.dark
                    }
                },
                popupIndicator: {
                    color: colors.alpha.black[50],

                    '&:hover': {
                        background: colors.primary.lighter,
                        color: colors.primary.main
                    }
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                toolbar: {
                    '& .MuiIconButton-root': {
                        padding: 8
                    }
                },
                select: {
                    '&:focus': {
                        backgroundColor: 'transparent'
                    }
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    minHeight: '0 !important',
                    padding: '0 !important'
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                head: {
                    background: colors.alpha.black[5]
                },
                root: {
                    transition: 'background-color .2s',

                    '&.MuiTableRow-hover:hover': {
                        backgroundColor: lighten(colors.alpha.black[5], 0.5)
                    }
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottomColor: colors.alpha.black[10],
                    fontSize: 14
                },
                head: {
                    textTransform: 'uppercase',
                    fontSize: 13,
                    fontWeight: 'bold',
                    color: colors.alpha.black[70]
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                message: {
                    lineHeight: 1.5,
                    fontSize: 14
                },
                standardInfo: {
                    color: colors.info.main
                },
                action: {
                    color: colors.alpha.black[70]
                }
            }
        },
        MuiTimelineDot: {
            styleOverrides: {
                root: {
                    margin: 0,
                    zIndex: 5,
                    position: 'absolute',
                    top: '50%',
                    marginTop: -6,
                    left: -6
                },
                outlined: {
                    backgroundColor: colors.alpha.white[100],
                    boxShadow: '0 0 0 6px ' + colors.alpha.white[100]
                },
                outlinedPrimary: {
                    backgroundColor: colors.alpha.white[100],
                    boxShadow: '0 0 0 6px ' + colors.alpha.white[100]
                }
            }
        },
        MuiTimelineConnector: {
            styleOverrides: {
                root: {
                    position: 'absolute',
                    height: '100%',
                    top: 0,
                    borderRadius: 50,
                    backgroundColor: colors.alpha.black[10]
                }
            }
        },
        MuiTimelineItem: {
            styleOverrides: {
                root: {
                    minHeight: 0,
                    padding: '8px 0',

                    '&:before': {
                        display: 'none'
                    }
                },
                missingOppositeContent: {
                    '&:before': {
                        display: 'none'
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: alpha(colors.alpha.black['100'], 0.95),
                    padding: '8px 16px',
                    fontSize: 13
                },
                arrow: {
                    color: alpha(colors.alpha.black['100'], 0.95)
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    height: 33,
                    overflow: 'visible',

                    '& .MuiButtonBase-root': {
                        position: 'absolute',
                        padding: 6,
                        transition:
                            'left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
                    },
                    '& .MuiIconButton-root': {
                        borderRadius: 100
                    },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                        opacity: 0.3
                    }
                },
                thumb: {
                    backgroundColor: colors.alpha.white[100],
                    border: '1px solid ' + colors.alpha.black[30],
                    boxShadow: '0px 9px 14px ' + colors.alpha.black[10] + ', 0px 2px 2px ' + colors.alpha.black[10]
                },
                track: {
                    backgroundColor: colors.alpha.black[5],
                    border: '1px solid ' + colors.alpha.black[10],
                    boxShadow: 'inset 0px 1px 1px ' + colors.alpha.black[10],
                    opacity: 1
                },
                colorPrimary: {
                    '& .MuiSwitch-thumb': {
                        backgroundColor: colors.alpha.white[100]
                    },

                    '&.Mui-checked .MuiSwitch-thumb': {
                        backgroundColor: colors.primary.main
                    }
                }
            }
        },
        MuiStepper: {
            styleOverrides: {
                root: {
                    paddingTop: 20,
                    paddingBottom: 20,
                    background: colors.alpha.black[5]
                }
            }
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    '&.MuiStepIcon-completed': {
                        color: colors.success.main
                    }
                }
            }
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: 'h1',
                    h2: 'h2',
                    h3: 'div',
                    h4: 'div',
                    h5: 'div',
                    h6: 'div',
                    subtitle1: 'div',
                    subtitle2: 'div',
                    body1: 'div',
                    body2: 'div'
                }
            },
            styleOverrides: {
                gutterBottom: {
                    marginBottom: 4
                },
                paragraph: {
                    fontSize: 17,
                    lineHeight: 1.7
                }
            }
        }
    },
    shape: {
        borderRadius: 6
    },

    shadows: [
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none'
    ]
};

const rtlBase: ThemeOptions = {
    direction: 'rtl',
    ...base,
    typography: rtl_typography
};

const ltrBase = {
    direction: 'ltr',
    ...base,
    typography: ltr_typography
};

function createTheme(config: { direction: Direction }): Theme {
    // const options: ThemeOptions = { direction: 'rtl' };
    console.log({ config });
    const theme = createMuiTheme({ ...(config.direction == 'rtl' ? rtlBase : ltrBase) });

    return theme as Theme;
}
export default createTheme;
