import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';

function Page({
    title,
    subtitle,
    actions,
    children
}: {
    title?: string;
    subtitle?: string;
    actions?: React.ReactNode;
    children: any;
}) {
    return (
        <Box px={5}>
            <Helmet>
                <title>PIS Analytics - {title}</title>
            </Helmet>

            <Grid container justifyContent="space-between" alignItems="flex-start">
                <Grid item>
                    <Box py={2}>
                        {title && (
                            <Typography variant="h3" gutterBottom>
                                <Box sx={{ fontWeight: 'light', m: 1 }}>{title}</Box>
                            </Typography>
                        )}
                        {subtitle && <Typography variant="subtitle2">{subtitle}</Typography>}
                    </Box>
                </Grid>
                {actions && (
                    <Grid item>
                        <Box py={2}>{actions} </Box>
                    </Grid>
                )}
                <Grid item xs={12}>
                    {children}
                </Grid>
            </Grid>
        </Box>
    );
}

export default Page;
