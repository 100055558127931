import { useState } from 'react';
import Modal from 'src/components/Modal';
import { useDebouncedSearch } from 'src/hooks/useDebouncedSearch';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import {
    Box,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow as MuiTableRow,
    TableContainer,
    Typography,
    TextField,
    InputAdornment
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { IdentityUser, User } from 'src/services/api';
import { IdentiyUsersService } from '../../services/api/services/IdentiyUsersService';
import CreateUser from './create';
import { useModal } from '../../hooks/useModal';

const TableRow = styled(MuiTableRow)(
    ({ theme }) => `
    :hover {
      cursor: pointer;
      background-color: ${theme.palette.grey[100]};
    }
`
);

export const SearchUsers = ({
    open,
    closeUserSearchModal,
    handleSuccessUserCreate
}: {
    open: boolean;
    closeUserSearchModal: () => void;
    handleSuccessUserCreate: () => any;
}) => {
    const { t } = useTranslation();

    const [foundUsers, setFoundUsers] = useState<IdentityUser[]>([]);

    const [tempUser, setTempUser] = useState({});

    const [isModalOpen, openModal, closeModal] = useModal();

    const onFoundUserClick = (user: User) => {
        setTempUser(user as User);
        closeUserSearchModal();
        openModal();
        setInputText('');
        setFoundUsers([]);
    };

    const searchUsers = async (email: any) => {
        const page = 0;
        const pageSize = 1000;
        const includeAlreadyEnrolled = false;

        try {
            const data = await IdentiyUsersService.searchUsersAsync(email, page, pageSize, includeAlreadyEnrolled);
            setFoundUsers(data);
        } catch (err) {
            console.error(err);
        }
    };

    const useSearchUsers = () => useDebouncedSearch((email: any) => searchUsers(email), 3);

    const { inputText, setInputText } = useSearchUsers();

    return (
        <>
            {open && (
                <Modal
                    open={open}
                    handleClose={closeUserSearchModal}
                    title={t('view.user.onboard.headline')}
                    maxWidth="lg"
                >
                    <Box my={5} px={2}>
                        <TextField
                            autoFocus
                            value={inputText}
                            onChange={(e) => setInputText(e.target.value)}
                            fullWidth
                            label={t('view.user.onboard.headline')}
                            color="primary"
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton>
                                            <SearchTwoToneIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    searchUsers(inputText);
                                }
                            }}
                        />

                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <MuiTableRow>
                                        <TableCell>{t('view.user.full_name')}</TableCell>
                                        <TableCell>{t('view.user.email')}</TableCell>
                                    </MuiTableRow>
                                </TableHead>
                                <TableBody>
                                    {foundUsers?.map((foundUser: User) => {
                                        return (
                                            <TableRow key={foundUser.id} onClick={() => onFoundUserClick(foundUser)}>
                                                <TableCell>
                                                    <Typography
                                                        variant="body1"
                                                        fontWeight="bold"
                                                        color="text.primary"
                                                        gutterBottom
                                                        noWrap
                                                    >
                                                        {`${foundUser.firstName} ${foundUser.lastName}`}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        variant="body1"
                                                        fontWeight="bold"
                                                        color="text.primary"
                                                        gutterBottom
                                                        noWrap
                                                    >
                                                        {foundUser.email}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Modal>
            )}

            {isModalOpen && (
                <Modal open={isModalOpen} handleClose={closeUserSearchModal} title={'Add User'} maxWidth="lg">
                    <CreateUser
                        user={tempUser as User}
                        handleSuccessUserCreate={() => {
                            closeModal();
                            handleSuccessUserCreate();
                        }}
                        handleClose={() => closeModal()}
                    />
                </Modal>
            )}
        </>
    );
};
