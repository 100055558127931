import { useState, useCallback } from 'react';

export const useModal = (afterClose?: any) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const openModal = useCallback(() => {
        setIsModalOpen(true);
    }, []);

    const closeModal = useCallback(() => {
        setIsModalOpen(false);
        if (afterClose) afterClose();
    }, [afterClose]);

    return [isModalOpen, openModal, closeModal] as const;
};
