import { FormInputDropdown, FormInputText, Options } from '../components/FormFields/FormFields';

export interface Field {
    name: string;
    label: string;
    type?: string;
    options?: Options;
    disabled?: boolean;
    multiple?: boolean;
}

interface Props {
    field: Field;
    item: any;
    errors: any;
    control: any;
}

const FormFieldsMap = ({ field, item, control, errors }: Props) => {
    if (field.type === 'select') {
        return (
            <FormInputDropdown
                options={field?.options || []}
                name={field.name}
                label={field.label}
                defaultValue={item[field.name]}
                control={control}
                errors={errors}
                disabled={field?.disabled || false}
                multiple={field?.multiple || false}
            />
        );
    } else {
        return (
            <FormInputText
                label={field.label}
                control={control}
                defaultValue={item[field.name] || ''}
                name={field.name}
                errors={errors}
            />
        );
    }
};

export default FormFieldsMap;
