/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Permissions {
    NONE = 'NONE',
    VIEW_AGE = 'VIEW_AGE',
    VIEW_BIRTH = 'VIEW_BIRTH',
    VIEW_GROUPS = 'VIEW_GROUPS',
    VIEW_USERS = 'VIEW_USERS',
    UPDATE_USER = 'UPDATE_USER',
    ENROLL_USER = 'ENROLL_USER',
    ALL = 'ALL',
}