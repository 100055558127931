import { Grid, Button } from '@mui/material';

import UsersTable from './UsersTable';
import { useModal } from 'src/hooks/useModal';
import { SearchUsers } from './SearchUsers';
import Page from 'src/components/Page';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useCallback, useState } from 'react';
import { Role, User, UsersService } from 'src/services/api';
import EditUser from './edit';
import Modal from 'src/components/Modal';

function Index() {
    const [isModalOpen, openModal, closeModal] = useModal();
    const [editDialog, openEditDialog, closeEditDialog] = useModal();

    const [users, setUsers] = useState<User[]>([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const [tempUser, setTempUser] = useState({});

    const fetchUsers = useCallback(async ({ params }) => {
        const { page, pageSize } = params;
        try {
            const res: User[] = await UsersService.getUsersListAsync(page, pageSize);
            setUsers(res);
            setCount(count);
        } catch (err) {
            console.error(err);
        }
    }, []);

    const handleSuccessRequest = () => {
        fetchUsers({ params: { page, pageSize } });
    };

    const handleCloseEditDialog = () => {
        closeEditDialog();
        setTempUser({});
    };

    return (
        <>
            <Page
                title="Users"
                actions={
                    <Button
                        sx={{ mt: { xs: 2, md: 0 } }}
                        variant="contained"
                        startIcon={<AddTwoToneIcon fontSize="small" />}
                        onClick={openModal}
                    >
                        Add User
                    </Button>
                }
            >
                <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
                    <Grid item xs={12}>
                        <UsersTable
                            users={users}
                            fetchUsers={fetchUsers}
                            count={count}
                            page={page}
                            setPage={setPage}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            handleEditUser={(user: User) => {
                                // map through user.role and render object of label and value
                                const roles = user?.roles?.map((role: Role) => {
                                    return {
                                        label: role,
                                        value: role
                                    };
                                });

                                setTempUser({ ...user, roles: roles });
                                openEditDialog();
                            }}
                        />
                    </Grid>
                </Grid>

                {
                    <SearchUsers
                        open={isModalOpen as boolean}
                        closeUserSearchModal={closeModal as any}
                        handleSuccessUserCreate={handleSuccessRequest}
                    />
                }

                {editDialog && tempUser && (
                    <Modal open={editDialog} handleClose={handleCloseEditDialog} title={'Edit User'} maxWidth="lg">
                        <EditUser
                            user={tempUser as User}
                            handleClose={handleCloseEditDialog}
                            handleSuccessRequest={handleSuccessRequest}
                        />
                    </Modal>
                )}
            </Page>
        </>
    );
}

export default Index;
