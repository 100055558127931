/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActorTypeRate } from '../models/ActorTypeRate';
import type { GenderCountResponse } from '../models/GenderCountResponse';
import type { StringRates } from '../models/StringRates';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class GroupsService {

    /**
     * @returns StringRates Success
     * @throws ApiError
     */
    public static getPopulationGroupsAsync(): CancelablePromise<Array<StringRates>> {
        return __request({
            method: 'GET',
            path: `/v1/grouping/groups`,
        });
    }

    /**
     * @returns StringRates Success
     * @throws ApiError
     */
    public static getPopulationStatusAsync(): CancelablePromise<Array<StringRates>> {
        return __request({
            method: 'GET',
            path: `/v1/grouping/status`,
        });
    }

    /**
     * @returns StringRates Success
     * @throws ApiError
     */
    public static getGenderCountAsync(): CancelablePromise<Array<StringRates>> {
        return __request({
            method: 'GET',
            path: `/v1/grouping/gender`,
        });
    }

    /**
     * @returns ActorTypeRate Success
     * @throws ApiError
     */
    public static getActorGrouping(): CancelablePromise<Array<ActorTypeRate>> {
        return __request({
            method: 'GET',
            path: `/v1/grouping/actor`,
        });
    }

    /**
     * @returns GenderCountResponse Success
     * @throws ApiError
     */
    public static getGenderCountAnalyticsAsync(): CancelablePromise<GenderCountResponse> {
        return __request({
            method: 'GET',
            path: `/v1/grouping/gender-analytics`,
        });
    }

}