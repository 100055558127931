import React, { useEffect } from 'react';
import FormCardChart from './FormCardChart';
import * as yup from 'yup';
import { options } from './ColumnBarChart';
import { AgeService } from '../../../services/api/services/AgeService';
import ColumnBarChart from './ColumnBarChart';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/app/rootReducer';

interface Parameters {
    maxAge: number;
    minAge: number;
    range: number;
}

function PopulationAgeBySex() {
    const [t] = useTranslation();

    const schema = yup.object().shape({
        maxAge: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required(),
        minAge: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required(),
        range: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required()
    });

    const fields = {
        attributes: [
            { name: 'minAge', label: t('view.common.min_age') },
            { name: 'maxAge', label: t('view.common.max_age') },
            { name: 'range', label: t('view.common.range') }
        ]
    };

    const [btnLoading, setBtnLoading] = React.useState(false);
    const [chartOptions, setChartOptions] = React.useState(options);
    const [parameters] = React.useState<Parameters>({ maxAge: 80, minAge: 20, range: 10 });

    const handleGetPopulationAgeBySex = async (data?: Parameters) => {
        const { maxAge, minAge, range } = data ? data : parameters;
        setBtnLoading(true);

        try {
            const res = await AgeService.getPopulationAgeBySexAsync(maxAge, minAge, range);
            const femaleData = res.map((data) => data.females) as Array<number>;
            const maleData = res.map((data) => data.males) as Array<number>;
            const categories = res.map((data) => data.fromAge + '-' + data.toAge);

            let total = 0;

            res.forEach((item) => {
                total = item?.totalCount ? total + item?.totalCount : total + 0;
            });

            console.log({ total });

            setChartOptions({
                series: [
                    { name: t('view.common.gender.female'), type: 'column', data: femaleData, color: '#ce93d8' },
                    { name: t('view.common.gender.male'), type: 'column', data: maleData, color: '#66bb6a' }
                ],
                xAxis: { ...chartOptions.xAxis, categories: categories },
                tooltip: {
                    formatter() {
                        return `<span style="font-size:10px">${this.x}</span><table></br>
                          ${this.series.name}: ${this.y} (%${Math.round((this.y / total) * 100)}) </br>
                            Total : ${total}
                        `;
                    }
                }
            });
        } catch (err) {
            console.log(err);
        } finally {
            setBtnLoading(false);
        }
    };

    useEffect(() => {
        handleGetPopulationAgeBySex();
    }, []);

    return (
        <FormCardChart
            schema={schema}
            handleFormSubmit={handleGetPopulationAgeBySex}
            btnLoading={btnLoading}
            handleReset={handleGetPopulationAgeBySex}
            fields={fields}
            variant="outlined"
            header={t('view.graph.population_age_by_sex.header')}
            item={{ minAge: 20, maxAge: 80, range: 10 }}
            // detail="The Population Age By Sex shows number of the population sex in a given age range"
        >
            <ColumnBarChart chartOptions={chartOptions} />
        </FormCardChart>
    );
}

export default PopulationAgeBySex;
