import { Role } from 'src/services/api';
interface RoleType {
    text: string;
}

interface MapType {
    [key: string]: RoleType;
}

export const getRoleLabel = (userRole: Role) => {
    const map: MapType = {
        admin: {
            text: 'Admin'
        }
    };

    const text = map[userRole.toLocaleLowerCase() as keyof MapType]?.text || userRole;

    return text;
};
