import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
const menuItems = ({ t }) => [
    {
        // heading: t('pages.sidebar.management'),
        items: [
            {
                name: t('view.sidebar.dashboard'),
                icon: DashboardIcon,
                link: '/'
            },
            {
                name: t('view.sidebar.users'),
                icon: GroupIcon,
                link: '/users'
            }
        ]
    }
];

export default menuItems;
