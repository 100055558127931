import React, { useEffect } from 'react';

// import ColumnBarChart from './ColumnBarChart';
// import ThreeDPieChart from './ThreeDPieChart';
import { options as columnBarChartOptions } from './PieChart';

import { GroupsService } from '../../../services/api/services/GroupsService';
import { Card, CardContent, CardHeader, Divider, Box } from '@mui/material';
// import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/app/rootReducer';
import { StringRates } from 'src/services/api';
import { setFemalePopulation, setMalePopulation, setPopulation } from 'src/slices/dataset';
import PieChart from './PieChart';
import { useQuery } from '@tanstack/react-query';

const GenderCount = () => {
    const [t] = useTranslation();

    const dispatch = useDispatch();
    const population = useSelector((state: RootState) => state.dataset.population);

    const [chartOptions, setChartOptions] = React.useState(columnBarChartOptions);

    function SortArray(x: any, y: any) {
        return x.unit.localeCompare(y.unit);
    }

    const { data } = useQuery(
        ['genderCount'],
        async () => {
            try {
                const res = (await GroupsService.getGenderCountAsync()) as any;

                let total = 0;

                const newRes = res.sort(SortArray);

                const seriesData = newRes?.map((data: StringRates) => {
                    if (data.count) {
                        if (data.unit == 'male') dispatch(setMalePopulation(data.count));
                        if (data.unit == 'female') dispatch(setFemalePopulation(data.count));
                        total = total + data.count;
                    }
                    return {
                        y: data.count,
                        color: data.unit === 'female' ? '#ce93d8' : '#66bb6a',
                        name: t(`view.common.gender.${data.unit}`)
                    };
                });
                const categories = newRes?.map((data: any) => data.unit?.toString()) as Array<string>;

                setChartOptions({
                    series: [{ name: t('view.common.total'), type: 'pie', data: seriesData }],
                    xAxis: { ...chartOptions.xAxis, categories: categories },
                    tooltip: {
                        headerFormat: '',
                        pointFormat:
                            '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
                            `${t('view.common.total')}: <b>{point.y}</b><br/>`
                    }
                });

                dispatch(setPopulation(total));

                return res;
            } catch (err) {
                console.log(err);
            }
        },
        {
            refetchOnWindowFocus: true,
            refetchInterval: 7000
        }
    );

    // const getData = async () => {
    //     try {
    //         const res = await GroupsService.getGenderCountAsync();

    //         let total = 0;

    //         const seriesData = res?.map((data: StringRates) => {
    //             if (data.count) {
    //                 if (data.unit == 'male') dispatch(setMalePopulation(data.count));
    //                 if (data.unit == 'female') dispatch(setFemalePopulation(data.count));
    //                 total = total + data.count;
    //             }
    //             return {
    //                 y: data.count,
    //                 color: data.unit === 'female' ? '#ce93d8' : '#66bb6a',
    //                 name: t(`view.common.gender.${data.unit}`)
    //             };
    //         });
    //         const categories = res?.map((data) => data.unit?.toString()) as Array<string>;

    //         setChartOptions({
    //             series: [{ name: t('view.common.total'), type: 'pie', data: seriesData }],
    //             xAxis: { ...chartOptions.xAxis, categories: categories },
    //             tooltip: {
    //                 headerFormat: '',
    //                 pointFormat:
    //                     '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
    //                     `${t('view.common.total')}: <b>{point.y}</b><br/>`
    //             }
    //         });

    //         dispatch(setPopulation(total));
    //     } catch (err) {
    //         console.log(err);
    //     }
    // };

    // useEffect(() => {
    //     getData();
    // }, []);

    return (
        <Card variant="outlined" style={{ minHeight: 640 }}>
            <CardHeader
                title={t('view.graph.gender_count.header')}
                // action={
                //     <Tooltip title="The Gender Count chart shows total gender count of the population">
                //         <IconButton>
                //             <InfoIcon />
                //         </IconButton>
                //     </Tooltip>
                // }
            />
            <Divider />
            <CardContent>
                {/* <ThreeDPieChart chartOptions={chartOptions} /> */}
                <Box sx={{ mt: 7 }}>
                    <PieChart chartOptions={chartOptions} />
                </Box>
                {/* <ColumnBarChart chartOptions={chartOptions} /> */}
            </CardContent>
        </Card>
    );
};

export default GenderCount;
