import { Outlet } from 'react-router-dom';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import Header from './Header/index';
import Sidebar from './Sidebar';

const MainWrapper = styled(Box)(
    ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;

        @media (min-width: ${theme.breakpoints.values.lg}px) {
            padding-left: 280px;
        }
`
);

const MainContent = styled(Box)(
    () => `
        margin-top: 100px;
        flex: 1 1 auto;
        overflow: auto;
`
);

const Index = () => {
    return (
        <>
            <Sidebar />
            <MainWrapper>
                <Header />
                <MainContent>
                    <Outlet />
                </MainContent>
            </MainWrapper>
        </>
    );
};

export default Index;
