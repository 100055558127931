import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';
import { InputLabel, MenuItem, Chip, OutlinedInput } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import { styled } from '@mui/material/styles';

interface Props {
    name: string;
    label: string;
    control: any;
    defaultValue: string;
    errors: any;
}

export const FormInputText = ({ name, control, label, defaultValue, errors }: Props) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue || ''}
            render={({ field }) => (
                <TextField
                    style={{ width: '100%' }}
                    fullWidth
                    variant="outlined"
                    label={label}
                    error={Object.prototype.hasOwnProperty.call(errors, name) ? true : false}
                    helperText={errors[name]?.message}
                    margin="dense"
                    size="small"
                    {...field}
                />
            )}
        />
    );
};

export type Option = {
    value: string;
    label: string;
};
export type Options = Array<Option>;

interface FormInputDropdownProps {
    name: string;
    label: string;
    control: any;
    defaultValue: any;
    options: Options;
    errors: any;
    disabled?: boolean;
    multiple?: boolean;
}

const ChipsWrapper = styled('div')(
    () => `
    display: flex;
    flex-wrap: wrap;
`
);

export const FormInputDropdown = ({
    name,
    label,
    options,
    defaultValue,
    control,
    errors,
    multiple
}: FormInputDropdownProps) => {
    return (
        <Controller
            name={name}
            control={control}
            // inputProps={{ style: { fontSize: 40 } }}
            defaultValue={multiple ? defaultValue?.map((dv: Option) => dv.value) || [] : defaultValue || ''}
            render={({ field }) => (
                <FormControl
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    size="small"
                    error={Object.prototype.hasOwnProperty.call(errors, name) ? true : false}
                >
                    <InputLabel id={name}>{label}</InputLabel>

                    <Select
                        labelId={name}
                        id={label}
                        style={{ width: '100%' }}
                        multiple={multiple}
                        input={<OutlinedInput label={label} />}
                        {...(multiple && {
                            renderValue: (selected: any) => (
                                <ChipsWrapper>
                                    {selected.map((value: any, i: number) => (
                                        <Chip key={i} label={value?.label || value} />
                                    ))}
                                </ChipsWrapper>
                            )
                        })}
                        {...field}
                    >
                        {options?.map((option, index) => {
                            return (
                                <MenuItem key={index} value={option.value}>
                                    {option.label || option}
                                </MenuItem>
                            );
                        })}
                    </Select>

                    <FormHelperText>{errors[name]?.message}</FormHelperText>
                </FormControl>
            )}
        />
    );
};
