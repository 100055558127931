import FormFieldsMap from '../../../helpers/FormFieldsMap';
import LoadingButton from '../../../components/Button/LoadingButton';
import { Grid } from '@mui/material';
import FormCard, { FormCardProps } from '../../../components/Form/FormCard';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Field } from '../../../helpers/FormFieldsMap';
import { useTranslation } from 'react-i18next';

export interface Fields {
    attributes: Field[];
}

interface Props extends FormCardProps {
    schema: any;
    handleReset: any;
    fields: Fields;
}

function FormCardChart({
    schema,
    handleFormSubmit,
    btnLoading,
    handleReset,
    fields,
    variant,
    header,
    children,
    detail,
    item
}: Props) {
    const [t] = useTranslation();

    const {
        handleSubmit,
        control,
        formState: { errors, isDirty },
        reset
    } = useForm({
        resolver: yupResolver(schema)
    });

    return (
        <FormCard
            variant={variant}
            handleSubmit={handleSubmit}
            handleFormSubmit={handleFormSubmit}
            btnLoading={btnLoading}
            header={header}
            detail={detail}
            actions={
                <LoadingButton
                    loading={btnLoading}
                    disabled={!isDirty}
                    onClick={() => {
                        reset();
                        handleReset();
                    }}
                >
                    {t('view.common.form.reset')}
                </LoadingButton>
            }
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {children}
                </Grid>
                {fields.attributes?.map((field, index) => (
                    <Grid item md={6} xs={12} lg={4} key={index}>
                        <FormFieldsMap field={field} item={item || {}} control={control} errors={errors} />
                    </Grid>
                ))}
            </Grid>
        </FormCard>
    );
}

export default FormCardChart;
