import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import { userSchema } from '../userSchema';
import { useSnackbar } from 'notistack';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useTranslation } from 'react-i18next';
import { useRoles } from '../useRoles';

import FormFieldsMap, { Field } from 'src/helpers/FormFieldsMap';
import { User, EnrollUserRequest } from 'src/services/api';
import { UsersService } from '../../../services/api/services/UsersService';
import { useLocales } from '../useLocales';
import LoadingButton from 'src/components/Button/LoadingButton';
import { useState } from 'react';

function CreateUser({
    user,
    handleSuccessUserCreate,
    handleClose
}: {
    user: User;
    handleSuccessUserCreate: () => void;
    handleClose: () => void;
}) {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const { roles } = useRoles();
    const { locales } = useLocales();

    const [btnLoading, setBtnLoading] = useState(false);

    const {
        handleSubmit,
        formState: { errors },
        control
    } = useForm<EnrollUserRequest>({
        resolver: yupResolver(userSchema)
    });

    const handleFormSubmit: SubmitHandler<EnrollUserRequest> = async (formData) => {
        setBtnLoading(true);
        try {
            await UsersService.enrollUserAsync(user.id as string, formData);
            enqueueSnackbar(t('pages.users.actions.userAdded'), { variant: 'success' });
            handleSuccessUserCreate();
        } catch (err) {
            console.log(err);
            enqueueSnackbar(t('pages.users,.actions.addingUserFailed'), {
                variant: 'error'
            });
        } finally {
            setBtnLoading(false);
        }
    };

    const fields: Field[] = [
        {
            name: 'locale',
            label: t('view.user.preferred_language'),
            type: 'select',
            options: locales
        },
        {
            name: 'roles',
            label: t('view.user.roles'),
            type: 'select',
            options: roles,
            multiple: true
        }
    ];

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Grid container spacing={3}>
                {fields.map((field: Field, index) => (
                    <Grid item md={6} xs={12} lg={6} key={index}>
                        <FormFieldsMap field={field} item={{}} control={control} errors={errors} />
                    </Grid>
                ))}
            </Grid>
            <Box marginTop={3}>
                <LoadingButton onClick={handleClose} variant="text" loading={btnLoading}>
                    {t('view.common.form.cancel')}
                </LoadingButton>
                <LoadingButton
                    loading={btnLoading}
                    onClick={handleSubmit(handleFormSubmit)}
                    variant="contained"
                    startIcon={<AddTwoToneIcon fontSize="small" />}
                >
                    {t('view.common.form.submit')}
                </LoadingButton>
            </Box>
        </form>
    );
}

export default CreateUser;
