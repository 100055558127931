import React, { FormEvent, FormEventHandler } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Material UI
import { Box, Card, CardContent, Divider, CardHeader, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
// Components
import LoadingButton from '../Button/LoadingButton';

import { SubmitHandler } from 'react-hook-form';

export interface FormCardProps {
    header?: string;
    detail?: string;
    variant?: undefined | 'outlined' | 'elevation';
    disabled?: boolean;
    btnLoading: boolean;
    handleSubmit?: any;
    handleFormSubmit: any;
    children?: React.ReactNode;
    actions?: React.ReactNode;
    item?: any;
}

type FormValues = {
    maxAge: number;
    minAge: number;
    range: number;
};

const FormCard = ({
    handleSubmit,
    handleFormSubmit,
    btnLoading,
    header,
    detail,
    disabled,
    variant,
    actions,
    children
}: FormCardProps) => {
    const [t] = useTranslation();

    const submitForm: SubmitHandler<FormValues> = (data) => {
        handleFormSubmit(data);
    };

    return (
        <form onSubmit={handleSubmit(submitForm)}>
            <Card variant={variant}>
                {header && (
                    <>
                        <CardHeader
                            title={header}
                            action={
                                detail && (
                                    <Tooltip title={detail}>
                                        <IconButton>
                                            <InfoIcon />
                                        </IconButton>
                                    </Tooltip>
                                )
                            }
                        />
                        <Divider />
                    </>
                )}

                <CardContent>{children}</CardContent>
                <Divider />
                <Box p={2} display="flex" justifyContent="flex-end">
                    {actions}
                    <LoadingButton
                        variant="contained"
                        loading={btnLoading}
                        type="submit"
                        color="primary"
                        disabled={disabled}
                        size="small"
                    >
                        {t('view.common.form.submit')}
                    </LoadingButton>
                </Box>
            </Card>
        </form>
    );
};

FormCard.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleFormSubmit: PropTypes.func.isRequired,
    btnLoading: PropTypes.bool.isRequired,
    header: PropTypes.string
};

export default FormCard;
