import { useContext } from 'react';

import { Box, Hidden, IconButton, Theme, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import HeaderUserbox from './Userbox';
import Logo from 'src/components/Logo';

import HeaderButtons from '../Header/Languages';

interface HeaderWrapperComponentProps {
    theme?: Theme;
}
const HeaderWrapper = styled(Box)<HeaderWrapperComponentProps>((props: { theme: Theme }) => {
    const { theme } = props;

    return `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        paddingLeft: ${theme.spacing(3)};
        paddingRight: ${theme.spacing(3)};
        right: 0;
        z-index: 5;
        background-color: ${theme.header.background};
        border-width: 1px;
        border-style: solid;
        border-color: ${theme.palette.grey[300]};
        position: fixed;
        justify-content: space-between;
        width: 100%;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`;
});

function Header() {
    const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

    return (
        <HeaderWrapper display="flex" alignItems="center">
            <Box display="flex" alignItems="center" px={1}>
                <Box mx={5}>
                    <Logo />
                </Box>
                {/* <Hidden lgUp>
                    <Logo />
                </Hidden> */}
            </Box>
            <Box display="flex" alignItems="center">
                <HeaderButtons />
                <HeaderUserbox />
                <Hidden lgUp>
                    <Tooltip arrow title="Toggle Menu">
                        <IconButton color="primary" onClick={toggleSidebar}>
                            {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
                        </IconButton>
                    </Tooltip>
                </Hidden>
            </Box>
        </HeaderWrapper>
    );
}

export default Header;
