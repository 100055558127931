import PopulationAge from './components/charts/PopulationAge';
import { Grid } from '@mui/material';
import PopulationAgeBySex from './components/charts/PopulationAgeBySex';
import AgeAverageBySex from './components/charts/AgeAverageBySex';
import YearlyBirth from './components/charts/YearlyBirth';
import GenderCount from './components/charts/GenderCount';
import Page from 'src/components/Page';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/rootReducer';
import { useMemo, useEffect, useState } from 'react';
import { QuickStats10, Props } from '../components/QuickStats';
import numberWithCommas from '../utils/numberWithCommas';
import PeopleIcon from '@mui/icons-material/People';
import WomanIcon from '@mui/icons-material/Woman';
import ManIcon from '@mui/icons-material/Man';
import { GroupsService } from 'src/services/api';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { useQuery } from '@tanstack/react-query';

const DashboardLayout = () => {
    const [t] = useTranslation();

    const population = useSelector((state: RootState) => state.dataset.population);
    const malePopulation = useSelector((state: RootState) => state.dataset.malePopulation);
    const femalePopulation = useSelector((state: RootState) => state.dataset.femalePopulation);

    const femalePopulationPercentage = (femalePopulation / population) * 100;
    const malePopulationPercentage = (malePopulation / population) * 100;

    const { data: dlsData } = useQuery(
        ['actors'],
        async () => {
            try {
                const res = await GroupsService.getActorGrouping();

                const tempDlsData = res.find((item) => item.id == 'muehlbauer-idms-api-m2m');

                return tempDlsData?.count || 0;
            } catch (err) {
                console.log(err);
            }
        },
        {
            refetchOnWindowFocus: true,
            refetchInterval: 7000
        }
    );

    const stats = useMemo(() => {
        return [
            {
                label: t('view.common.total_population'),
                value: numberWithCommas(population) || 0,
                icon: <PeopleIcon />
            },
            {
                label: t('view.common.total_male'),
                value: malePopulation
                    ? `${numberWithCommas(malePopulation)} (%${malePopulationPercentage?.toFixed(2)}) `
                    : 0,
                icon: <ManIcon />
            },
            {
                label: t('view.common.total_female'),
                value: femalePopulation
                    ? `${numberWithCommas(femalePopulation)} (%${femalePopulationPercentage?.toFixed(2)})`
                    : 0,
                icon: <WomanIcon />
            },
            {
                label: t('view.common.dls'),
                value: dlsData ? `${numberWithCommas(dlsData)} (%${((dlsData / population) * 100).toFixed(2)})` : 0,
                icon: <DriveEtaIcon />
            },
            {
                label: t('view.common.payroll'),
                value:
                    dlsData && population
                        ? `${numberWithCommas(population - dlsData)} (%${(
                              ((population - dlsData) / population) *
                              100
                          ).toFixed(2)})`
                        : 0,
                icon: <AccountBalanceIcon />
            }
        ];
    }, [population, malePopulation, femalePopulation, femalePopulationPercentage, malePopulationPercentage, dlsData]);

    return (
        <Page title={t('view.dashboard.name.headline')}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <QuickStats10 items={stats} />
                </Grid>
                {/* <Grid item xs={12} md={6} lg={6} xl={4}>
                    <PopulationAge />
                </Grid> */}
                <Grid item xs={12} md={6} lg={6} xl={4}>
                    <PopulationAgeBySex />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={4}>
                    <GenderCount />
                </Grid>

                <Grid item xs={12} md={6} lg={6} xl={4}>
                    <YearlyBirth />
                </Grid>
                {/* <Grid item xs={12} md={6} lg={6} xl={4}>
                    <AgeAverageBySex />
                </Grid> */}
            </Grid>
        </Page>
    );
};

export default DashboardLayout;
