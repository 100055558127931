import React, { useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export const options: Highcharts.Options = {
    chart: {
        type: 'column'
    },
    title: {
        text: ''
    },
    subtitle: {},
    xAxis: {
        categories: [],
        crosshair: true
    },
    yAxis: {
        min: 0,
        title: {
            text: ''
        }
    },
    legend: {
        enabled: true
    },
    credits: {
        enabled: false
    },

    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    series: [],
    tooltip: {}
};

function ColumnBarChart({ chartOptions, props }: { chartOptions: Highcharts.Options; props?: HighchartsReact.Props }) {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

    return <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartComponentRef} {...props} />;
}

export default ColumnBarChart;
