import React, { useEffect } from 'react';
import FormCardChart from './FormCardChart';
import * as yup from 'yup';
import ColumnBarChart from './ColumnBarChart';
import { options as columnBarChartOptions } from './ColumnBarChart';
import { BirthService } from '../../../services/api/services/BirthService';

import { BirthSortBy } from '../../../services/api/index';
import { SortingDirection } from '../../../services/api/index';
import { useTranslation } from 'react-i18next';

interface Parameters {
    year?: number;
    sortBy?: BirthSortBy;
    sortDir?: SortingDirection;
}

const YearlyBirth = () => {
    const [t] = useTranslation();

    const schema = yup.object().shape({
        year: yup.number().transform((value) => (isNaN(value) ? undefined : value)),
        sortBy: yup.string(),
        sortDir: yup.string()
    });

    const fields = {
        attributes: [
            { name: 'year', label: t('view.common.year') },
            {
                name: 'sortBy',
                label: t('view.common.sort_by'),
                type: 'select',
                options: [
                    {
                        value: 'date',
                        label: t('view.common.date')
                    },
                    {
                        value: 'count',
                        label: t('view.common.count')
                    }
                ]
            },
            {
                name: 'sortDir',
                label: t('view.common.sort_dir'),
                type: 'select',
                options: [
                    {
                        value: 'ascending',
                        label: t('view.common.ascending')
                    },
                    {
                        value: 'descending',
                        label: t('view.common.descending')
                    }
                ]
            }
        ]
    };

    const [btnLoading, setBtnLoading] = React.useState(false);
    const [chartOptions, setChartOptions] = React.useState(columnBarChartOptions);
    const [parameters] = React.useState<Parameters>({});

    function toMonthName(monthNumber: any) {
        const date = new Date();
        date.setMonth(monthNumber - 1);

        return date.toLocaleString('en-US', {
            month: 'long'
        });
    }

    const getData = async (data?: Parameters) => {
        const { year, sortBy, sortDir } = data ? data : parameters;
        setBtnLoading(true);

        try {
            const res = await BirthService.getMonthlyBirthsAsync(year, sortBy || undefined, sortDir || undefined);
            const seriesData = res?.map((data) => data.count) as Array<number>;
            const categories = res?.map((data) => toMonthName(data.month).toString()) as Array<string>;

            let total = 0;

            res?.forEach((item) => {
                total = item?.count ? total + item?.count : total + 0;
            });

            setChartOptions({
                series: [
                    { name: t('view.graph.yearly_birth.birth_total'), type: 'column', data: seriesData?.reverse() }
                ],
                xAxis: { ...chartOptions.xAxis, categories: categories?.reverse() },
                tooltip: {
                    formatter() {
                        return `
                          ${this.x}: ${this.y} (%${Math.round((this.y / total) * 100)}) </br>
                            Total : ${total}
                        `;
                    }
                }
            });
        } catch (err) {
            console.log(err);
        } finally {
            setBtnLoading(false);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <FormCardChart
            schema={schema}
            handleFormSubmit={getData}
            btnLoading={btnLoading}
            handleReset={getData}
            fields={fields}
            variant="outlined"
            header={t('view.graph.yearly_birth.header')}
            item={{}}
            // detail="The Yearly Birth chart shows the total number of bizrths per month for a given year."
        >
            <ColumnBarChart chartOptions={chartOptions} />
        </FormCardChart>
    );
};

export default YearlyBirth;
