import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import UsersService from '../services/UsersService';
import { RootState } from '../app/rootReducer';

const datasetSlice = createSlice({
    name: 'dataset',
    initialState: {
        population: 0,
        malePopulation: 0,
        femalePopulation: 0
    },
    reducers: {
        setPopulation(state, action) {
            state.population = action.payload;
        },

        setMalePopulation(state, action) {
            state.malePopulation = action.payload;
        },

        setFemalePopulation(state, action) {
            state.femalePopulation = action.payload;
        }
    }
});

export const { reducer } = datasetSlice;

export const { setPopulation, setMalePopulation, setFemalePopulation } = datasetSlice.actions;

// export const usePopulation = () => {
//     return useSelector((state: RootState) => state.dataset.dataset);
// };
