import React, { useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export const options: () => Highcharts.Options = () => ({
    chart: {
        plotBackgroundColor: undefined,
        plotBorderWidth: undefined,
        plotShadow: false,
        type: 'pie'
    },
    title: {
        text: ''
    },
    tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.2f} %'
            }
        }
    },
    series: [
        {
            name: 'Pie Chart',
            colorByPoint: true,
            type: 'pie',
            data: []
        }
    ],
    credits: {
        enabled: false
    }
});

function PieChart({ chartOptions, props }: { chartOptions: Highcharts.Options; props?: HighchartsReact.Props }) {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

    return <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartComponentRef} {...props} />;
}

export default PieChart;
