import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, Typography, Box } from '@mui/material';
import LoadingButton from 'src/components/Button/LoadingButton';
import { styled } from '@mui/material/styles';
import { Breakpoint } from '@mui/system';

const ModalLoadingButton = styled(LoadingButton)(
    ({ theme }) => `
    & + & {
      margin-left: ${theme.spacing(1.5)};
    }
`
);

interface ActionButton {
    loading: boolean;
    text: string;
}

const Modal = ({
    open,
    handleClose,
    title,
    actionButtons,
    maxWidth,
    children,
    bgColor,
    ...rest
}: {
    open: boolean;
    handleClose: () => void;
    title: string;
    actionButtons?: ActionButton[];
    maxWidth?: Breakpoint;
    children?: any;
    bgColor?: string;
}) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth={maxWidth || 'xl'}
            fullWidth={true}
            PaperProps={{ elevation: 0 }}
            {...rest}
        >
            <DialogContent style={{ backgroundColor: bgColor }}>
                {title && (
                    <Box my={1}>
                        <Typography variant="h4" color="textPrimary" style={{ fontWeight: 600 }}>
                            {title}
                        </Typography>
                    </Box>
                )}

                {children}

                {actionButtons && (
                    <Box mt={2}>
                        <DialogActions>
                            {actionButtons.map((button, index) => (
                                <ModalLoadingButton key={index} loading={button.loading}>
                                    {button.text}
                                </ModalLoadingButton>
                            ))}
                        </DialogActions>
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
};

Modal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    actionButtons: PropTypes.array
};

export default React.memo(Modal);
