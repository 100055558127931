import React from 'react';
import { Card, Divider, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Label from 'src/components/Label';
import { getRoleLabel } from '../../components/labels/getRoleLabel';
import { GetStatusLabel } from '../../components/labels/GetStatusLabel';
import { ActiveStatus, Role, User } from 'src/services/api';

const ListWrapper = styled(List)(
    () => `
      .MuiListItem-root {
        border-radius: 0;x
        margin: 0;
      }
`
);

function ProfileDetails({ currentUser }: { currentUser: User }) {
    const { t } = useTranslation();

    return (
        <Card variant="outlined">
            <ListWrapper disablePadding>
                <ListItem>
                    <ListItemText primary={t('view.user.username')} secondary={currentUser?.username} />
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemText primary={t('view.user.email')} secondary={currentUser?.email} />
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemText
                        primary={t('view.user.preferred_language')}
                        secondary={t(`view.languages.${currentUser.locale}`)}
                    />
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemText
                        primary={t('view.user.status')}
                        secondary={<GetStatusLabel userStatus={currentUser?.activeStatus as ActiveStatus} t={t} />}
                    />
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemText
                        primary={t('view.user.roles')}
                        secondary={currentUser?.roles?.map((role: Role, index: number) => (
                            <Label key={index} color="primary">
                                {getRoleLabel(role as Role)}
                            </Label>
                        ))}
                    />
                </ListItem>
            </ListWrapper>
        </Card>
    );
}

export default ProfileDetails;
