import React from 'react';
import Label from '../Label';

interface StatusType {
    text: string;
    color: string;
}

interface MapType {
    [key: string]: StatusType;
}

export const GetStatusLabel = ({ userStatus, t, textOnly }: { userStatus: string; t: any; textOnly?: boolean }) => {
    const map: MapType = {
        active: {
            text: 'active',
            color: 'success'
        },
        inactive: {
            text: 'inactive',
            color: 'error'
        }
    };

    if (!map[userStatus.toLocaleLowerCase() as keyof MapType]) return <></>;

    const { text, color } = map[userStatus.toLocaleLowerCase() as keyof MapType];

    if (textOnly) return <>{text}</>;

    return <Label color={color}>{t ? t(`view.common.status.${text}.label`) : text}</Label>;
};
