import axios from 'axios';
import { api } from '../utils/axios';

class UsersService {
    async getCurrentIdentityServerUser() {
        const res = await axios.get('/auth/userinfo');
        return res.data;
    }

    async getCurrentUser() {
        const res = await api.get('/user/self');
        return res.data;
    }

    async getUsers({ params }: { params?: any } = {}) {
        const res = await api.get('/users', { params });
        return { users: res.data, count: parseInt(res.headers.totalcount) };
    }

    async getOne({ userId }: { userId?: number }) {
        const res = await api.get(`/users/${userId}`);
        return res.data;
    }

    async search({ email }: { email?: string }) {
        const res = await api.get('/users/search_to_onboard', { params: { email } });
        return res.data;
    }

    async create({ data }: { data?: any }) {
        const res = await api.post('/users', data);
        return res.data;
    }

    async edit({ userId, data }: { userId?: number; data?: any } = {}) {
        const res = await api.put(`/users/${userId}`, data);
        return res.data;
    }

    async getCurrentUserNoInterceptor() {
        const res = await axios.get('/api/v1/user/self');
        return res.data;
    }
}
export default new UsersService();
