import React, { useCallback, useEffect } from 'react';
import './assets/css/custom.css';
import './App.css';
import { RouteObject, useRoutes } from 'react-router-dom';
import routes from './routes';
import UsersService from './services/UsersService';
import { useDispatch } from 'react-redux';
import { setIsAuthenticatingLoading, setUser } from './slices/user';
import createTheme from './theme/schemes/LightTheme';
import { ThemeProvider } from '@mui/material/styles';
import { OpenAPI } from './services/api/index';
import './i18n';
import { SnackbarProvider } from 'notistack';
import { setIdentityServerUser } from 'src/slices/user';
import { StylesProvider } from '@mui/styles';
import { CacheProvider } from '@emotion/react';
import rtlPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';
import i18n from 'i18next';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

// Create rtl cache
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [rtlPlugin]
});

const RtlProvider = ({ dir, children }: { dir: any; children: any }) => {
    if (dir === 'rtl') return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
    else return children;
};

const getDirection = () => (i18n.language === 'en' ? 'ltr' : 'rtl');

function App() {
    OpenAPI.BASE = '/api';

    const dispatch = useDispatch();
    const routing = useRoutes(routes as RouteObject[]);

    const getUser = useCallback(async () => {
        try {
            const currentUser = await UsersService.getCurrentUserNoInterceptor();
            dispatch(setUser(currentUser));
        } catch (err) {
            dispatch(setIsAuthenticatingLoading(false));
        }
    }, []);

    const getIdentityServerUser = useCallback(async () => {
        try {
            const currentIdentityServerUser = await UsersService.getCurrentIdentityServerUser();
            dispatch(setIdentityServerUser(currentIdentityServerUser));
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        getUser();
        getIdentityServerUser();
    }, []);

    const theme = createTheme({
        direction: getDirection()
    });

    return (
        <div className="App" dir={getDirection()}>
            <StylesProvider injectFirst>
                <RtlProvider dir={getDirection()}>
                    <ThemeProvider theme={theme}>
                        <QueryClientProvider client={queryClient}>
                            <SnackbarProvider
                                dense
                                maxSnack={3}
                                preventDuplicate
                                // TransitionComponent={Fade}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                            >
                                {routing}
                            </SnackbarProvider>
                        </QueryClientProvider>
                    </ThemeProvider>
                </RtlProvider>
            </StylesProvider>
        </div>
    );
}

export default App;
