import { Grid } from '@mui/material';
import ProfileHeader from './ProfileHeader';
import ProfileDetails from './ProfileDetails';
import { useSelector } from 'react-redux';
import Page from 'src/components/Page';
import { RootState } from 'src/app/rootReducer';

function Account() {
    const currentUser = useSelector((state: RootState) => state?.user?.currentUser);

    return (
        <Page title="Account">
            {currentUser && (
                <Grid container direction="row" spacing={1} alignItems="flex-start">
                    <Grid item xs={12}>
                        <ProfileHeader currentUser={currentUser} />
                    </Grid>
                    <Grid item xs={12}>
                        <ProfileDetails currentUser={currentUser} />
                    </Grid>
                </Grid>
            )}
        </Page>
    );
}

export default Account;
