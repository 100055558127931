import {
    Box,
    Card,
    CardContent as MuiCardContent,
    Container as MuiContainer,
    Button,
    Typography,
    Alert,
    AlertTitle
} from '@mui/material';
import { LockClosedIcon as MuiLockClosedIcon } from '@heroicons/react/solid';
import { styled } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../app/rootReducer';

const Container = styled(MuiContainer)(
    () => `
    padding-bottom: 80px;
    padding-top: 14vh;
`
);

const CardContent = styled(MuiCardContent)(
    ({ theme }) => `
    padding: ${theme.spacing(4)};
    display: flex;
    flex-direction: column;
`
);

const TypographyLogo = styled(Typography)(
    ({ theme }) => `
    color: ${theme.palette.grey[400]};
    margin: ${theme.spacing(0, 0.8)};
`
);

const LockClosedIcon = styled(MuiLockClosedIcon)(
    ({ theme }) => `
    width: 40px;
    color: ${theme.palette.primary.main};
    margin: ${theme.spacing(0, 1)};
`
);

const login = () => {
    window.location.href = '/auth/login';
};

const logout = () => {
    window.location.href = '/auth/logout';
};

const LoginView = () => {
    const { t } = useTranslation();
    const { currentUser, identityServerUser } = useSelector((state: RootState) => state.user);

    return (
        <Container title="Login">
            <Container maxWidth="sm">
                <Card>
                    <CardContent>
                        <Box mt={1} mb={4.5} display="flex" flexDirection="column" alignItems="center">
                            <Box display="flex" alignItems="flex-end">
                                <LockClosedIcon />
                            </Box>

                            <Box display="flex" alignItems="flex-end">
                                <TypographyLogo variant="h2" align="center">
                                    {t('view.pisa')}
                                </TypographyLogo>
                            </Box>

                            {identityServerUser && !currentUser ? (
                                <Box mt={3}>
                                    <Alert severity="error">
                                        <AlertTitle>{t('view.auth.login.unauthorized')}</AlertTitle>
                                        {t('view.auth.login.unauthorized_message')}
                                    </Alert>
                                </Box>
                            ) : (
                                <>
                                    <Box mt={3.4} px={4}>
                                        <Typography color="textPrimary" gutterBottom variant="h2" align="center">
                                            {t('view.auth.login.headline')}
                                        </Typography>
                                    </Box>
                                    <Box px={2}>
                                        <Typography variant="body2" color="textSecondary" align="center">
                                            {t('view.auth.login.subtitle')}
                                        </Typography>
                                    </Box>
                                </>
                            )}
                        </Box>
                        <Box flexGrow={1} mb={2}>
                            {identityServerUser && !currentUser ? (
                                <Button color="primary" fullWidth size="large" onClick={logout} variant="contained">
                                    {t('view.auth.logout.headline')}
                                </Button>
                            ) : (
                                <Button color="primary" fullWidth size="large" onClick={login} variant="contained">
                                    {t('view.auth.login.label')}
                                </Button>
                            )}
                        </Box>
                    </CardContent>
                </Card>
            </Container>
        </Container>
    );
};

export default LoginView;
