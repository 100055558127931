import React, { useState } from 'react';
import { IconButton, SvgIcon, Tooltip, Menu, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import i18n from 'src/i18n';

export const LANGUAGES = {
    en: 'English',
    ckb: 'کوردی'
};

const Languages = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = useState(null);
    const { t } = useTranslation();
    const user = useSelector((state) => state.user.currentUser);
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLanguageChange = (newLanguage) => {
        i18n.changeLanguage(newLanguage);
        window.location.reload();
        // changeLanguage(
        //     newLanguage,
        //     () => {
        //         enqueueSnackbar(t('header.alerts.languageChanged'), {
        //             variant: 'info'
        //         });
        //     },
        //     () => {
        //         enqueueSnackbar(t('header.alerts.errorChangingLanguage'), {
        //             variant: 'error'
        //         });
        //     }
        // );

        handleClose();
    };

    return (
        <>
            <Tooltip arrow title={t('view.languages.label')}>
                <IconButton color="primary" onClick={handleOpen}>
                    <SvgIcon>
                        <LanguageTwoToneIcon fontSize="large" />
                    </SvgIcon>
                </IconButton>
            </Tooltip>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                {Object.keys(LANGUAGES).map((language) => (
                    <MenuItem
                        key={language}
                        onClick={() => handleLanguageChange(language)}
                        selected={language === user?.preferred_language || language === i18n.language}
                    >
                        {LANGUAGES[language]}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default Languages;
