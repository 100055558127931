import React, { FC } from 'react';
import { Grid, Box, Avatar, Card, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';

type Item = {
    icon?: any;
    value?: string | number;
    label?: string | number;
    variant?: 'elevation' | 'outlined' | undefined;
    color?: string;
    backgroundColor?: string;
};

export interface Props {
    items: Item[];
}

export const QuickStats10: FC<Props> = ({ items }) => {
    return (
        <Grid container spacing={4}>
            {items
                .filter((item) => item)
                .map((item, index) => {
                    const { icon, label, value, ...rest } = item;
                    return (
                        <Grid item xl={4} lg={6} md={6} xs={12} {...rest} key={index}>
                            <Card variant={item?.variant || 'outlined'} sx={{ p: 3 }}>
                                <Box
                                    sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        mb: 1
                                    }}
                                >
                                    {icon && (
                                        <Avatar
                                            sx={{
                                                backgroundColor: (theme) =>
                                                    item?.backgroundColor
                                                        ? item?.backgroundColor
                                                        : alpha(theme.palette.primary.main, 0.2),
                                                color: item?.color ? item.color : (theme) => theme.palette.primary.main,
                                                height: 50,
                                                width: 50
                                            }}
                                            variant="rounded"
                                        >
                                            {icon}
                                        </Avatar>
                                    )}
                                    <Box sx={{ mx: 2 }}>
                                        <Typography color="textSecondary" variant="body2" textAlign="start">
                                            {label}
                                        </Typography>
                                        <Typography variant="h2" textAlign="start">
                                            {value}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>
                    );
                })}
        </Grid>
    );
};
