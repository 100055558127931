import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import UsersService from '../services/UsersService';
import { RootState } from '../app/rootReducer';

const userSlice = createSlice({
    name: 'user',
    initialState: {
        currentUser: null,
        identityServerUser: null,
        isAuthenticated: false,
        isAuthenticationLoading: true
    },
    reducers: {
        setUser(state, action) {
            state.currentUser = action.payload;
            state.isAuthenticated = true;
            state.isAuthenticationLoading = false;
        },

        setIdentityServerUser(state, action) {
            state.identityServerUser = action.payload;
        },

        logout(state) {
            state.currentUser = null;
            state.isAuthenticated = false;
        },

        setIsAuthenticatingLoading(state, action) {
            state.isAuthenticationLoading = action.payload;
        }
    }
});

export const { reducer } = userSlice;

export const { setUser, setIdentityServerUser, setIsAuthenticatingLoading } = userSlice.actions;

export const useCurrentUser = () => {
    return useSelector((state: RootState) => state.user.currentUser);
};

export const useAuthentication = () => {
    return useSelector((state: RootState) => state.user.isAuthenticated);
};

interface currentUser {
    id: number;
}

export const updateCurrentUserLanguage = async (preferredLanguage: string, currentUser: currentUser, dispatch: any) => {
    const updatedUser = await UsersService.edit({
        userId: currentUser?.id,
        data: {
            user: {
                preferred_language: preferredLanguage
            }
        }
    });
    await dispatch(userSlice.actions.setUser(updatedUser));
};
