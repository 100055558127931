import { Box, Typography, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getRoleLabel } from '../../components/labels/getRoleLabel';
import Label from '../../components/Label';
import { Role, User } from 'src/services/api';

const AvatarWrapper = styled(Box)(
    ({ theme }) => `
    .MuiAvatar-root {
      width: ${theme.spacing(16)};
      height: ${theme.spacing(16)};
    }
`
);

const ProfileHeader = ({ currentUser }: { currentUser: User }) => {
    return (
        <>
            <Box display="flex" mb={4} alignItems="center">
                <AvatarWrapper>
                    <Avatar variant="rounded" alt={currentUser.username as string} />
                </AvatarWrapper>

                <Box px={2}>
                    <Typography gutterBottom variant="h4">
                        {currentUser.username}
                    </Typography>
                    <Typography gutterBottom variant="subtitle1">
                        {currentUser.email}
                    </Typography>
                    <Typography sx={{ pt: 2 }} variant="subtitle2" color="text.primary">
                        {currentUser?.roles?.map((role: Role, index) => (
                            <Label key={index} color="primary">
                                {getRoleLabel(role as Role)}
                            </Label>
                        ))}
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

ProfileHeader.propTypes = {};

export default ProfileHeader;
