import React from 'react';
import { Button as MuiButton, ButtonProps, CircularProgress as MuiCircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const ErrorButton = styled(MuiButton)(
    ({ theme }) => `
    color: #fff;
    background-color: ${theme.palette.error.main};
    :hover {
      background-color: ${theme.palette.error.dark};
  }
`
);

const CircularProgress = styled(MuiCircularProgress)(
    () => `
    color: #bdbdbd;
    margin-right: 7px;
`
);

interface Props extends ButtonProps {
    loading?: boolean;
    disabled?: boolean;
    children?: React.ReactNode;
    colorVariant?: string;
    className?: string;
    variant?: 'text' | 'outlined' | 'contained';
    type?: 'submit' | 'button';
    // color?: '"inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning"';
}

function LoadingButton({ children, loading, disabled, colorVariant, className, ...rest }: Props) {
    const Button = colorVariant === 'error' ? ErrorButton : MuiButton;

    return (
        <Button {...rest} disabled={loading || disabled} className={className}>
            {loading && <CircularProgress size={18} />}
            {children}
        </Button>
    );
}

export default LoadingButton;
