import { combineReducers } from '@reduxjs/toolkit';
import { reducer as userReducer } from '../slices/user';
import { reducer as datasetReducer } from '../slices/dataset';

const rootReducer = combineReducers({
    user: userReducer,
    dataset: datasetReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
